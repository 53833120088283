<template>
  <div id="FamilyIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.stuName" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad" style="margin-bottom:64px">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <span @click="toStuDeatil(row)"> <b style="font-size:15px;color:black">{{row.stuName}}</b> {{  row.stuNo}}</span> <van-icon name="arrow" color="#23AB72" @click="toDetail(row)" />
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="clock-o" /> 处分时间: {{parseTime(row.disciplinaryAt, "{y}-{m}-{d}")}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="newspaper-o" /> 处分结果: {{codeConvertLabel(row.punishResult,dict.disciplinary_punish_res)}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="notes-o" /> 处分期限: {{ codeConvertLabel(row.punishAt,dict.disciplinary_punish_date)}}
            </div>
          </template>
          <template #right-icon>
            <span v-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <div class="ckeckbox-st" @click="checkHandel(row)" v-if="showAuditBtn">
              <van-checkbox v-model="row.checked" icon-size="21px" shape="square"></van-checkbox>
            </div>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" @click="toDel(row)" v-if="row.approvalStatus !== 9 " :disabled="[201,301,3,9].indexOf(row.approvalStatus) > -1" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" @click="toEdit(row)" v-if="row.approvalStatus !== 9" :disabled="[201,301,3,9].indexOf(row.approvalStatus) > -1" plain round size="small" type="primary">编辑</van-button>
            <van-button class="table-edit-btn" style="width:100px" @click="toUpload(row)" v-if="row.approvalStatus === 9 && showAuditBtn" plain round size="small" type="info">上传处分决定</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div class="submit-button shadow-style" v-if="showAdd">
      <van-button round block type="primary" @click="doAdd">新 增</van-button>
    </div>
    <div class="batch-button" v-if="showAuditBtn">
      <van-row>
        <van-col span="5">
          <van-checkbox class="batch-checked" @click="selcetAll" v-model="checkedAll" checked-color="#23AB72">全选</van-checkbox>
        </van-col>
        <van-col span="9">
          <div class="batch-checked-text" v-html="selectionText"></div>
        </van-col>
        <van-col span="10">
          <van-button round block type="primary" color="#23AB72" :disabled="this.selections.length ===0" @click="batchAudit()">批量审批</van-button>
        </van-col>
      </van-row>
    </div>
    <van-dialog v-model="showAudit" title="审批" :showConfirmButton="false" width="90%">
      <div @click="toCloseAudit(false)" class="cancel">取消</div>
      <van-form ref="form">
        <van-field v-model="audit.appendData.opinion" :border="true" rows="3" label="审核意见" type="textarea" maxlength="50" placeholder="请输入审核意见" show-word-limit :rules="[{ required: true, message: '请输入审核意见' }]" />
      </van-form>
      <van-row style="padding:10px 10px 20px 10px" justify="center">
        <van-col span="8" align="center">
          <van-button type="warning" :loading="auditLoading1" :disabled="auditDisabled1" round style="width:80%" @click="confirmAudit(8)">驳回</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="danger" :loading="auditLoading2" :disabled="auditDisabled2" round style="width:80%" @click="confirmAudit(9)">拒绝</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="primary" :loading="auditLoading3" :disabled="auditDisabled3" round style="width:80%" @click="confirmAudit(1)">同意</van-button>
        </van-col>
      </van-row>
    </van-dialog>
    <van-dialog v-model="showUpload" title="上传处分决定书" :beforeClose="uploadPunishResolve">
      <div @click="closeUploadFile" class="cancel">关闭</div>
      <van-cell-group title="处分决定">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="uploadForm.fileId" miz="STU_PUNISH_RESOLVE" biz="storage" :multiple="3" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
    </van-dialog>
    <switch-role @change="toQuery()"></switch-role>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import crudStuDisciplinaryManage from '@/api/modules/daily/disciplinary'
import batchAudit from "@/mixins/batchAudit";
import fileUpload from "@/components/FileUpload";

export default {
  name: "StuPayReport",
  mixins: [initData, batchAudit],
  components: { fileUpload },
  dicts: ["xj_shzt", "xj_nj", "disciplinary_punish_res", "disciplinary_punish_date"],
  data() {
    return {
      loading: false,
      showAuditBtn: false,
      showAdd: false,
      audit: {
        appendData: {
          opinion: "同意",
          like: [],
          field3: "",
        },
        coreKey: "",
        type: "DISCIPLINARY",
        callback: "api/stuDisciplinaryManage/approval",
        mkCode: "DISCIPLINARY_MANAGE",
        coreCode: "DISCIPLINARY_MANAGE_AUDIT",
        status: 9,
      },
      showUpload: false,
      refsh: true,
      uploadForm: {
        fileId: [],
        id: null,
      }
    }
  },
  methods: {
    resetField() {
      this.audit = {
        appendData: {
          opinion: "同意",
          like: [],
          field3: "",
        },
        coreKey: "",
        type: "DISCIPLINARY",
        callback: "api/stuDisciplinaryManage/approval",
        mkCode: "DISCIPLINARY_MANAGE",
        coreCode: "DISCIPLINARY_MANAGE_AUDIT",
        status: 9,
      }
    },
    beforeInit() {
      this.url = "/api/stuDisciplinaryManage";
      this.sort = ['approvalStatus,asc', 'schoolYear,desc'];
      this.beforeRefresh()
      return true;
    },
    beforeRefresh() {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      if (roles.indexOf('自身') > -1) {
        this.query.approvalStatus = '2,9,101,201,301,108,208,308'
        this.showAdd = true
        this.showAuditBtn = false
      }
      if (roles.indexOf('本级') > -1) {
        this.query.approvalStatus = '2,3,9,201,301,208,308'
        this.showAdd = false
        this.showAuditBtn = true
      }
      if (roles.indexOf('全部') > -1) {
        this.showAdd = false
        this.showAuditBtn = true
        let roleName = this.user.roles.map((item) => {
          return item.name
        })
        if (roleName.indexOf('超级管理员') === -1) {
          this.query.approvalStatus = '3,9,301,308'
        }
      }
    },
    toUpload(data) {
      this.uploadForm.id = data.id
      if (data.files) {
        this.uploadForm.fileId = JSON.parse(data.files)
        this.refsh = false
        this.$nextTick((e) => {
          this.refsh = true
        })
      } else {
        this.uploadForm.fileId = []
      }
      this.showUpload = true
    },
    uploadResponse(fileIds) {
      this.uploadForm.fileId = fileIds
    },
    closeUploadFile() {
      this.uploadForm = {
        fileId: [],
        id: null,
      }
      this.refsh = false
      this.$nextTick((e) => {
        this.refsh = true
      })
      this.showUpload = false
    },
    uploadPunishResolve(action, done) {
      if (this.uploadForm.fileId && this.uploadForm.fileId.length > 0 && action === 'confirm') {
        crudStuDisciplinaryManage.uploadFile(this.uploadForm).then((res) => {
          this.$notify({
            message: '上传成功',
            type: 'success',
            duration: 2000,
          })
          done()
          this.toQuery()
        })
      } else {
        done(false)
        this.$notify({
          message: '请上传决定书',
          type: 'warning',
          duration: 2000,
        })
      }
    },
    toDetail(data) {
      this.$router.push({
        path: "/js/disciplinary/detail",
        query: { id: data.id, status: data.cancelApprovalStatus, spzt: data.approvalStatus },
      });
    },
    doAdd() {
      this.$router.push({
        path: "/js/disciplinary/form",
      });
    },
    toEdit(data) {
      this.$router.push({
        path: "/js/disciplinary/form",
        query: { id: data.id },
      });
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          crudStuDisciplinaryManage.del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    async confirmAudit(type) {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      let value = []
      if (roles.indexOf('本级') > -1) {
        value = this.selections.filter((item) => {
          return ['2'].indexOf(item.approvalStatus + '') > -1
        })
        this.audit.appendData.field3 = 2
      }
      if (roles.indexOf('全部') > -1) {
        value = this.selections.filter((item) => {
          return ['3'].indexOf(item.approvalStatus + '') > -1
        })
        this.audit.appendData.field3 = 3
      }
      this.audit.status = type
      if (value.length > 0) {
        await this.$refs.form.validate().then(
          (res) => {
            this.loadingStatus(type)
            this.selections.forEach((element) => {
              this.audit.coreKey = element.id;
              this.auditApi(this.audit).then((resp) => {
                if (resp.success) {
                  this.toCloseAudit(true);
                  this.resetField();
                }
              });
            });

          },
          (err) => { }
        );
        this.toQuery()
      } else {
        this.$notify({
          type: "success",
          message: "请选择需要自己审核的数据",
          duration: 2000,
        });
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
.ckeckbox-st {
  font-size: 21px;
  top: 50%;
  position: absolute;
  right: 5%;
}
.cancel {
  color: #999999;
  position: absolute;
  top: 20px;
  right: 30px;
}
</style>